/* Media query for screens smaller than 576px (extra small devices like smartphones) */
@media (max-width: 767px) {
  .wrapper {
      display: block;
    }
    .sidebar-inner {
      width: auto;
      height: auto;
      display: block;
      padding: 24px 24px 32px;
    }
    .menu {
      padding-top: 24px;
    }
    .intro {
      display: none;
    }
    .main {
      padding: 0 24px 40px 24px;
    }
    .content-card {
      display: block;
    }
    .card-unique {
      margin-bottom: 24px;
    }
    .page-content .self-photo {
      float: none;
      width: 100%;
      margin: 0 0 24px 0;
    }
    .projects {
      display: block;
      &>div {
        margin-bottom: 24px;
      }
    }
    .personal-projects {
      padding-bottom: 0;
    }
    .bookshelf {
      grid-auto-flow: row;
      grid-template-columns: repeat(2, 50%);
      grid-gap: 16px;
    }
    .experience-inner::before {
      height: 177px;
      top: 110px;
    }
    .experience-inner::after {
      top: 285px;
    }
    a.visit {
      width: 100%;
      padding: 16px 0;
      text-align: center;
    }
    .title-area {
      h1, h2 {
        padding: 16px 0;
        text-align: center;
      }
    }
    .image-wrapper {
      display: block;
      figure {
        margin-bottom: 32px;
      }
    }
}