/*** Variables ***/
$base-font-size: 15px;
$dark-gray: #35303F;
$light-gray: lighten($dark-gray, 40%);
$lighter-gray: lighten($dark-gray, 75%);
$accent-color: #484e61;

$normal-transition: all 0.3s ease-in-out;
$hard-shadow:  3px 3px 0 $dark-gray;



/**
 * Reset some basic elements
 */
 body, h1, h2, h3, h4, h5, h6,
 p, blockquote, pre, hr,
 dl, dd, ol, ul, figure {
   margin: 0;
   padding: 0;
   color: $dark-gray;
   font-family: Inter, Helvetica, Arial, sans-serif;

 }

 h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  color: $dark-gray;
 }


 a {
  display: inline-block;
  color: $accent-color;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

 p, ul li {
  font-size: $base-font-size;
  line-height: $base-font-size*1.5;
  font-weight: 400;
 }

 ul {
  margin-bottom: 20px;
 }

.intro p {
  color: $light-gray;
  font-size: .85rem;
  line-height: $base-font-size*1.2;
}

/** Layout **/


.wrapper {
  position: relative;
  display: grid;
  grid: "sidebar main";
  grid-template-rows: 100vh;
  grid-template-columns: minmax(50px, 250px) 6fr;
}

.sidebar h1, .sidebar h2, .sidebar nav, .sidebar .intro {
  padding-left: 40px;
}

.sidebar-inner {
  width: 100%;
  height: calc(100% - 56px);
  display: grid;
  grid-auto-flow: column;
  grid:
    "title-area" 4fr
    "intro" 1fr
    / 1fr;
  padding-top: 48px;
  span {
    font-size: 1rem;
    vertical-align: middle;
    color: $light-gray;
  }
}

.title-area {
  grid-area: title-area;
}

.menu {
  grid-area: menu;
  padding-top: 40px;
}

.intro {
  grid-area: intro;
  padding-bottom: 20px;
}

.main { 
  overflow-y: scroll;
  padding: 48px 20px 56px 40px;
}

.main {
  grid-area: main;
}

.main-inner {
  max-width: 1000px;
  padding-left: 24px;
}

/** End Layout **/

.sidebar h1 {
  border-bottom: 1px solid $dark-gray;
  padding-bottom: 16px;
  line-height: 1.6rem;
  text-transform: uppercase;
}

.sidebar h2 {
font-size: 1rem;
padding-top: 8px;
}

.sidebar a {
  text-decoration: none;
  color: $dark-gray;
  display: block;
  padding: 8px;
  transition: $normal-transition;
  &:hover {
    background-color: $lighter-gray;
    color: $accent-color;
  }
}

.sidebar a.active {
  font-weight: bold;
  pointer-events: none;
  background-color: $lighter-gray;
  color: $accent-color;
  &:hover {
    background: none;
  }
}

.page-content {
  h1 {
    font-size: $base-font-size * 2.5;
    margin-bottom: 30px;
  }
  h2, h3 {
    margin-bottom: 20px;
  }
  h3 {
    color: $accent-color;
  }
  h2, h3 {
    margin-top: 30px;
  }
  .self-photo {
    width: 225px;
    float: right;
    margin: 0 0 20px 20px;
  }
  p { 
    margin-bottom: $base-font-size;
  }
  blockquote {
    font-size: $base-font-size*1.7;
    color: $accent-color;
    font-style: italic;
    width: 80%;
    text-align: center;
    margin: 0 auto;
    padding: 16px 0;
  }
}

.projects {
  width: 100%;
  display: grid;
  grid:
    "scribulous illustration" 1fr
    / 1fr 1fr;
  grid-auto-flow: column;
  gap: 0px 80px;
}

.projects img {
  width: 100%;
}

.projects h3 {
  margin-top: 0;
}

.scribulous { grid-area: scribulous; }
.illustration { grid-area: illustration; }

a.visit {
  display: inline-block;
  background: $accent-color;
  text-decoration: none;
  padding: 10px 15px;
  color: #fff;
  margin: 8px 0;
  transition: $normal-transition;
  &:hover {
    background: darken($accent-color, 20%);
  }
  span {
    font-size: 1rem;
    vertical-align: middle;
  }
}

.bookshelf {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(1fr);
  gap: 0px 8px;
  grid-auto-flow: column;
}

.book-card a {
  width: initial;
  display: flex;
  align-items: center;
  &:hover {
    img {
      transform: rotate3d(0,1,0,-25deg);
      box-shadow: -10px 0 0 lighten($accent-color, 25%);
      filter: saturate(80%) brightness(70%);
    }
  }
  img {
    height: 225px;
    transition: $normal-transition;
  }
}

.book-card h3 {
  max-width: 150px;
  margin: 16px 0 0 0;
}

.bookshelf img {
  width: 150px;
  border-radius: 0;
  box-shadow: -5px 0 0 lighten($accent-color, 15%);
  max-height: 220px ;
  border: 1px solid $light-gray;
}

.experience h2 {
  margin-bottom: 20px;
}

.experience {
  position: relative;
  padding: 40px 0;
}

.experience-inner {
  height: 200px;
  &::before {
    content: '';
    display: block;
    width: 4px;
    height: 135px;
    background: $accent-color;
    position: absolute;
    left: 16px;
    top: 124px;
  }
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 40px;
    border-left: 4px dotted $accent-color;
    position: absolute;
    left: 16px;
    top: 260px;
    z-index: 0;
  }
}

.job {
  margin-left: 20px;
  padding: 10px 0 10px 30px;
  position: relative;
  h4 {
    font-weight: normal;
  }
}

.job::before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 1000px;
  border: 4px solid $accent-color;
  background: white;
  position: absolute;
  left: -11px;
  top: 14px;
  z-index: 10;
}

.content-card {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 16px;
}

.card-unique {
  border: 1px solid $dark-gray;
  box-shadow: $hard-shadow;
  
  padding: 25px;
  h2 {
    clear: both;
    margin-bottom: 10px;
    font-size: 1.4rem;
    line-height: 2rem;
  }
  h5 {
    margin-bottom: 16px;
    color: $accent-color;
    font-weight: 600;
  }
  img.post-hero {
    display: none;
    visibility: hidden;
  }
}

.right {
  float: right;
}
.clear {
  clear: both;
}

.site-intro {
  padding: 0 16px;
  h3 {
    font-weight: 400;
    background: none;
  }
}

.card-unique {
  h2 {
    margin: 0 0 16px 0;
  }
}
.card-image {
    display: inline-block;
    width: 100%;
    height: 200px;
    overflow: hidden;
    position: relative;
    &:hover {
      img {
        transform: scale(1.05);
        filter: grayscale(0%);
      }
    }
    img {
      width: 120%;
      filter: grayscale(100%);
      position: absolute;
      object-fit: fill;
      left: -10px;
      top: -10px;
      transition: $normal-transition;
    }
  }

  .portfolio-wrapper {
    .card-unique {
      padding: 8px;
    }
    .card-text {
      padding: 16px;
    }
  }

  .work-section {
    padding: 40px 0;
    border-top: 1px solid lighten($light-gray, 25%);
    border-bottom: 1px solid lighten($light-gray, 25%);
    h2 {
      margin-bottom: 20px;
    }
  }

  .posts-section {
    padding: 40px 0;
  }

.portfolio-wrapper .content-card{
  grid-template-columns: repeat(2, 1fr);
}

p.copyright{
  font-size: $base-font-size*0.7;
  margin-top: 24px;
  a {
    display: inline;
    color: $light-gray;
    &:hover {
      background: none;
      text-decoration: underline;
    }
  }
}

.me-intro, .motivations, .personal-projects {
  h2 {
    margin: 0;
  }
  padding: 40px 0;
  border-bottom: 1px solid lighten($light-gray, 25%);
}

.me-intro {
  padding-top: 0;
}

.personal-projects {
  h2 {
    margin-bottom: 16px;
  }
}

#logo {
  visibility: hidden!important;
}

.hellofresh-hero {
  position: relative;
}
.hellofresh-hero::before {
  content: '';
  display: block;
  width: 140px;
  height: 40px;
  position: absolute;
  background: white;
  bottom: 18px;
  right: 18px;
  z-index: 100;
}

.image-wrapper {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 35% 65%;
  gap: 0px 24px;
  grid-auto-flow: column;
  img {
    width: 100%;
  }
  
}

figure {
  padding: 32px 0;
}

figcaption {
  font-size: .9em;
  color: $light-gray;
  text-align: center;
}

.case-section {
  ul li span {
    font-weight: bold;
    color: $accent-color;
  }
}

.hf-wireframe {
  text-align: center;
  img {
    max-width: 200px;
    margin: 0 auto;
  }
}

.hf-process {
  img {
    width: 100%;
  }
}

.hf-video-wrapper {
  background: #292828;
  padding: 32px 0;
  video {
    display: block;
    margin: 0 auto;
  }
}

.logo-vector {
  fill: $dark-gray;
}

#Layer_2 {
  width: 120px;
  margin-right: 15px;
}

.site-intro {
  display: grid;
  grid-template-columns: 1fr 6fr;
  .intro-image {
    float: left;
    margin-right: 20px;
  }
  .intro-text {
    display: flex;
    align-items: center;
  }
}

.motivations {
  h3 {
    margin-bottom: 0;
  }
}

.image-full {
  max-width: 700px;
  margin: 20px;
}

.image-small {
  max-width: 300px;
  margin: 20px;
}

.icon-guidelines {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  img {
    width: 700px;
  }
}

.icon-final {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  img {
    max-width: 70px;
    margin: 10px;
    transition: $normal-transition;
    &:hover {
      transform: scale(1.5);
    }
  }
}

.container {
  display: grid;
  grid-template-columns: minmax(200px, 17%) 1fr auto;
  grid-template-rows: 100vh;
}

.left {
  background: yellow;
}

.right {
  background: red;
  overflow-y: scroll;
}

.copyright {
  span {
    transition: $normal-transition;
    font-size: 1.1em;
  }
  a {
    padding: 0;
    vertical-align: baseline;
    &:hover span {
      color: red;
      transform: scale(1.2);
    }
  }
}

.wireframes {
  display: flex;
  background: $light-gray;
  padding: 0;
  padding-top: 16px;
  position: relative;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 70px;
    background: black;
    background: linear-gradient(0deg, rgba(255,255,255,1) 34%, rgba(255,255,255,0) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
  }
  &>div {
    padding: 0 16px;
    max-width: 300px;
    max-height: 550px;
    overflow-y: scroll;
    margin-bottom: 8px;
    img {
      width: 100%;
    }
  }
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'wght' 800,
  'GRAD' 0,
  'opsz' 24
}

.post-navigation {
  border-top: 1px solid lighten($light-gray, 25%);
  padding-top: 20px;
  margin-top: 20px;
  p {
    margin: 0;
  }
}

.post-navigation a {
  span {
    vertical-align: middle;
  }
}

.post-hero {
  width: 100%;
}



